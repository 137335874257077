<template>
  <v-app>
    <v-app-bar height="30" app color="primary" dark>
      <MHEADER> </MHEADER>
    </v-app-bar>
    <v-main class="maintainer">
      <div class="maindiv" :style="marginResize">
        <PANBAN />
        <STANSHEET />
        <FOOTER />
      </div>
    </v-main>
  </v-app>
</template>

<script>

import PANBAN from '@/components/rsideTools';
import STANSHEET from '@/components/stan_sheet';
import MHEADER from "@/components/vheader";
import FOOTER from "@/components/footer";

export default {
  name: 'App',

  components: {
    PANBAN,
    STANSHEET,
    MHEADER,
    FOOTER,
  },

  data: () => ({

  }),
  computed:{
    marginResize(){
      const marginleft=/Mobi|Android|iPhone/i.test(navigator.userAgent)?"0%":"15%";
      
      return `margin-left:${marginleft}`;
    }
  },
  methods: {
  }
};
</script>
<style lang="less">
.maintainer{
  display: flex;
  justify-content: center;
  align-items: center;
  .maindiv{
    width: 55%;
  }
}
</style>
