<template>
    <v-tooltip right>
      <template v-slot:activator="{ on, attrs }">
        <div class="vchip" :style="cellColor" v-bind="attrs" v-on="on">
          <!-- <span v-show="isLeader">*</span> -->
          <span>{{ mbobj.oriName }}</span>
          <span v-show="isShudi">*</span>
        </div>
      </template>
      <template>
        <table>
          <tr>
            姓名：{{ mbobj.oriName }}
          </tr>
          <tr>
            站点：{{ mbobj.station }}
          </tr>
          <tr>
            隶属：{{ mbobj.belong }}
          </tr>
          <tr>
            岗位：{{ mbobj.grade }}
          </tr>
          <tr>
            外出开始时间：{{ mbobj.cmddate | shorttime }}
          </tr>
          <tr>
            计划撤离时间：{{ mbobj.plandate | shorttime }}
          </tr>
        </table>
      </template>
    </v-tooltip>
</template>

<script>
import { lhParse } from "@/js/dts.js";


export default {
  props: {
    mbobj: Object,
  },
  data() {
    return {
      viewTitle: false,
    };
  },
  computed: {
    LunHuangMessage() {
      if (this.hasLunHuang) {
        let lp = lhParse(this.mbobj);
        return `${lp.mes} ${lp.text}`;
      } else {
        return "";
      }
    },
    isLeader(){
      return this.mbobj.grade.includes("管理")
    },
    isShudi(){
      return !this.mbobj.belong.includes(this.mbobj.station)
    },
    hasLunHuang() {
      return this.mbobj.cmd.endsWith("ing");
    },

    cellColor() {
      return this.mbobj.grade.includes("机械员")
        ? "backgroundColor:lightblue"
        : "backgroundColor:rgb(230, 216, 188)"
    },
  },
  methods: {},
  filters: {
    shorttime(timestr) {
      let d = new Date(timestr);
      if (isNaN(d.getTime())) {
        return timestr;
      } else {
        return d.toLocaleDateString();
      }
    },

  },
};
</script>
<style>

.vchip {
  border-radius: 5%;
  width: 80px;
  height: 20px;
  margin-right: 2px;
  margin-bottom: 2px;
  display: flex;
  justify-content: center;
  /* color: rgb(230, 216, 188); */
}

</style>