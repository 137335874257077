<template>
  <v-card>
    <div class="p_right">
      <div class="p_memos ma-2 flexcenter">
        <div class="text-center">

          <a href="javascript:void(0)" @click="showdetail = !showdetail">
            {{ showdetail ? "值班表▲" : "值班表▼" }}</a><span> - </span>
          <a href="javascript:void(0)" @click="toNow">今日</a>值班:
          <span style="font-size: 15px; color: darkblue">
            白班☀{{ zdTurn(pb_current.toarB) }}
          </span>
          ,
          <span style="font-size: 15px; color: darkblue">
            晚班☽{{ zdTurn(pb_current.toarN) }}
          </span>
        </div>
        <div v-if="showdetail">
          <v-row class="mt-2" style="height: 65px">
            <v-col cols="8">
              <v-select align="start" outlined dense class="ml-2" label="航线值班表" hint="选择一个月份" v-model="selectMonth"
                @change="monthSelect" :items="yms">
              </v-select></v-col>
          </v-row>
          <p />
          <table :style="{ 'border-spacing': '3px 0px' }">
            <PBCELLS :month_data_last="monthDataHead">
              <template #default="{ val }">
                <li style="
                  list-style: none;
                  background: #75bac2;
                  font-weight: bolder;
                  font-size: 17px;
                ">
                  <span style="color:white; text-align: left">{{ `${zdTurn(val.dyv.dayValue)} - ` }}</span><span
                    style="color: darkblue; text-align: right">{{ zdTurn(val.dyv.nightValue) }}</span>
                </li>
              </template>
            </PBCELLS>

            <PBCELLS :month_data_last="monthDataLast"> </PBCELLS>
          </table>

          <!-- <router-link v-if="false" to="/">[LATER]</router-link> -->
          <LATER />
        </div><!--Hiddend-->
      </div>
      <!-- div memos -->
    </div>
    <!-- div right -->
  </v-card>
</template>

<script>
import PBCELLS from "./pb_cell"
import LATER from "./later"

const __now = new Date();
const _NOW = {
  year: __now.getFullYear(),
  month: __now.getMonth() + 1,
  day: __now.getDate(),
  week: week(__now),
};

function __getseril(y, m, d) {
  let cha = new Date(y, m - 1, d) - new Date(1900, 0, 2);
  return Math.floor(cha / 1000 / 3600 / 24) % 4;
}

function* cyles(cur = 0, count = 1) {
  let ar = [2, 1, 3, 4];
  do {
    yield ar[cur++ % 4];

  } while (--count > 0);
}

function week(aDate) {
  return ["日", "一", "二", "三", "四", "五", "六"][aDate.getDay()];
}

function _zz(m = _NOW.month, y = _NOW.year) {
  let __y = {
    2: 4,
    1: 2,
    3: 1,
    4: 3,
  };
  let result = [];
  let maxDay = new Date(y, m, 0).getDate();
  let i = 0;
  for (let ser of cyles(__getseril(y, m, 1), maxDay)) {
    let xuhao = String(i + 1).padStart(2, "0");
    let today = new Date(y, m - 1, i + 1);
    let dayObj = {
      number: xuhao,
      dayValue: ser,
      nightValue: __y[ser],
      week: week(today),
    };
    result.push(dayObj);
    i++;
  }

  return result;
}

function __paiBanJiSuan(toar_b) {
  let __x = [2, 1, 3, 4];
  let today_n = (toar_b + 3) % 4;
  let yest_b = today_n;
  let yest_n = (toar_b + 2) % 4;
  let tomo_b = (toar_b + 1) % 4;
  return {
    yestB: __x[yest_b],
    yestN: __x[yest_n],
    toarB: __x[toar_b],
    toarN: __x[today_n],
    tomoB: __x[tomo_b],
    tomoN: __x[toar_b],
  };
}

function rightDataTreat(sliceNum, ym = _NOW) {
  let cyle_data = _zz(ym.month, ym.year);
  let outData = [];
  let row, innerIndex;
  let today_date = new Date().getDate();
  for (let va = 0; va < cyle_data.length; va++) {
    innerIndex = va % sliceNum;
    if (innerIndex == 0) {
      row = [];
      outData.push(row);
    }
    row[innerIndex] = {
      dyv: cyle_data[va],
    };

    row[innerIndex].single = va % 2 == 0;
    row[innerIndex].fous =
      ym.year == _NOW.year && ym.month == _NOW.month && va == today_date - 1;
  }
  return outData;
}

class SimpleDate {
  constructor({ year = _NOW.year, month = _NOW.month }) {
    this.year = year;
    this.month = month;
  }
  add() {
    if (this.month == 12) {
      this.month = 1;
      this.year++;
    } else {
      this.month++;
    }
  }
  red() {
    if (this.month == 1) {
      this.month = 12;
      this.year--;
    } else {
      this.month--;
    }
  }

  reset({ year, month }) {
    this.year = year;
    this.month = month;
  }

  step(up = true) {
    if (up) {
      this.add();
    } else {
      this.red();
    }
  }

  get go() {
    return { year: this.year, month: this.month };
  }

  *cyle(from = this, to = this, option = { up: true, mc: 0 }) {
    if (!option.mc) {
      if (
        (from.year * 12 + from.month <= to.year * 12 + to.month &&
          !option.up) ||
        (from.year * 12 + from.month >= to.year * 12 + to.month && option.up)
      ) {
        return;
      }
      option.mc = to.comp(from);
    }

    for (let i = 0; i <= option.mc; i++) {
      yield from.go;
      from.step(option.up);
    }
  }
  comp(other) {
    return Math.abs(
      this.year * 12 + this.month - other.year * 12 - other.month
    );
  }
}

export default {
  name: "comPaiban",
  components: {
    PBCELLS,
    LATER
  },
  data() {
    let paiban = __paiBanJiSuan(__getseril(_NOW.year, _NOW.month, _NOW.day));
    let monthAllData = rightDataTreat(4);
    return {
      pb_current: paiban,
      monthDataHead: [monthAllData[0]],
      monthDataLast: monthAllData.slice(1),
      selectMonth: { year: _NOW.year, month: _NOW.month },
      currentMonth: { year: _NOW.year, month: _NOW.month },
      showdetail: false,
    };
  },

  computed: {
    yms() {
      let selMo = new SimpleDate(this.selectMonth);

      let result = [];
      let findFn = function (m, text, rel) {
        let fr = rel.find(
          (v) => v.value.year == m.year && v.value.month == m.month
        );
        if (fr) {
          fr.text = text;
        }
      };

      for (let m of selMo.cyle(selMo, undefined, { up: true, mc: 5 })) {
        result.unshift({
          text: `${m.year}年${m.month}月`,
          value: m,
        });
      }

      selMo.reset(this.selectMonth);
      selMo.red();
      for (let m of selMo.cyle(selMo, undefined, { up: false, mc: 12 })) {
        result.push({
          text: `${m.year}年${m.month}月`,
          value: m,
        });
      }

      selMo.reset(this.currentMonth);
      findFn(selMo.go, `本月(${selMo.month}月)`, result);
      selMo.add();
      findFn(selMo.go, `下个月(${selMo.month}月)`, result);
      selMo.red();
      selMo.red();
      findFn(selMo.go, `上个月(${selMo.month}月)`, result);

      return result;
    },
  },
  methods: {
    zdTurn(n) {
      return ["①", "②", "③", "④"][n - 1];
    },
    monthSelect: function () {
      let monthAllData = rightDataTreat(4, this.selectMonth);
      this.monthDataHead = [monthAllData[0]];
      this.monthDataLast = monthAllData.slice(1);

    },
    toNow() {
      this.selectMonth = { year: _NOW.year, month: _NOW.month };
      this.monthSelect();
    },
  },
  filters: {},
  created() { },
  mounted() { }

};
</script>

<style>
.p_right {
  /* position: fixed; */
  background-color: #dedede;
  margin-top: 1.7%;
  margin-left: 10%;
  min-width: 80%;
  width: 80%;
}

.p_memos {
  margin-top: 1.7%;
  margin-left: 1%;
  width: 100%;
  /* background-color: #aaaaaa; */
}

.flexcenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>