<template>
  <div>
    <v-card>
      {{ serviceMsg }}
      <v-card-text class="ma-0 pa-0">
        <v-expansion-panels focusable v-model="panel" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <!-- sd_en0,sd_me1,sd_tl2,pq_en3,pq_me4,pq_tl5,
t1_en6, t1_me7,t2_en8,t2,me9,t3_en10,t3_me11,t4_en12,t4_me13,
cq_en14,cq_me15,cq_tl=16 -->
              <v-row no-gutters>
                <v-col cols="12" md="12" sm="5">
                  <div class="stationschips" style="margin-bottom: 2px;">
                    <v-chip color="primary" small label>
                      <b>所有外站</b>
                    </v-chip>
                    <v-chip small label>{{ `总人数${counts}` }}</v-chip>
                    <v-chip small label>驻外{{ zw_counts }}人</v-chip>
                    <v-chip small label>属地{{ sd_counts }}人</v-chip>
                    <v-chip small label>出差{{ cc_counts }}人</v-chip>
                  </div>
                </v-col>
                <v-col cols="12" md="12" sm="5">
                  <v-banner elevation="2" class="text-overline">
                    ◇属地人员:放行{{ allStiaionCpts[0] }}人，机械员{{
                      allStiaionCpts[1]
                    }}人<br />
                    ◇一队派遣:放行{{ allStiaionCpts[6] }}人，机械员{{
                      allStiaionCpts[7]
                    }}人<br />
                    ◇二队派遣:放行{{ allStiaionCpts[8] }}人，机械员{{
                      allStiaionCpts[9]
                    }}人<br />
                    ◇三队派遣:放行{{ allStiaionCpts[10] }}人，机械员{{
                      allStiaionCpts[11]
                    }}人<br />
                    ◇四队派遣:放行{{ allStiaionCpts[12] }}人，机械员{{
                      allStiaionCpts[13]
                    }}人<br />
                    ◇其他派遣:放行{{ allStiaionCpts[14] }}人，机械员{{
                      allStiaionCpts[15]
                    }}人<br />
                  </v-banner>
                </v-col>
              </v-row>
              <!-- ♠ ♣ ♧ ♡ ♥ ❤ ❥ ❣ ♂ ♀ ✲ ☀ ☼ ☽☾ ◐ ◑ ☺ ☻ ☎ ☏ ✿ ❀ ✎ ✐ ✌ ✍ ☜ ☞ ♋ ☄
 ☊ ☋ ☌☍☢ ⁂ 〄 ↂ ❦❧ ஐ ﻬ ๑ ♈ ღ ✟ ☂ ♨ ☃ ۩ ♟ ✙ ✈ ✉ ✁ 〠 ⊹⊱⋛⋋ ⋌
 ⋚⊰⊹ ❆ ❁ ☸ ☑ ☒ ♀♂‖$ @ * & # ※ 卍 卐 Ψ № ♫ ♬ ♭ ♩ ♪ ♯ ♮ ⌒ ¶ ∮ 
 ‖ € ￡ ¥ $ ☜☞☎☏✄☪☣☢☠❦❧☭✐✌✍✓✔✕✖♂♀♥♠♣♦♧♤♨☪✎✟ஐ♈ ⓛⓞⓥⓔ ๑۩۩۩۩๑ ๑۩۞۩๑ ღღღ ￡Ю§ -->
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-simple-table dense fixed-header>
                <template v-slot:default>
                  <thead>
                    <tr class="text-left">
                      <th>序号</th>
                      <th>姓名</th>
                      <th>岗位</th>
                      <th>隶属</th>
                      <th>派驻开始时间</th>
                      <th>计划撤离时间</th>
                      <th>是否出差</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in sts" :key="i" class="text-left">
                      <td>{{ i + 1 }}</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.grade }}</td>
                      <td>
                        {{ `${item.belong}:${item.station}` }}
                      </td>
                      <td>{{ item.cmddate | shorttime }}</td>
                      <td>{{ item.plandate | shorttime }}</td>
                      <td>{{ item.isbtrip == 1 ? '是' : '' }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-for="(sGroup, j) in GroupByStation" :key="j">
            <v-expansion-panel-header>
              <v-row no-gutters>
                <v-col cols="12" md="12" sm="5">
                  <div class="stationschips" style="margin-bottom: 2px;">
                    <v-chip color="primary" small label>
                      <b>{{ sGroup.stationName }}</b>
                    </v-chip>
                    <v-chip small label>
                      共{{ sGroup.counts | fixTowNumber }}人
                    </v-chip>
                    <v-chip small label v-show="sGroup.charger != ''">
                      负责人：{{ sGroup.charger }}
                    </v-chip>
                    <v-chip small label>
                      放行{{ sGroup.fxCounts | fixTowNumber }}人
                    </v-chip>
                    <v-chip small label>
                      机械员{{ sGroup.jxyCounts | fixTowNumber }}人
                    </v-chip>
                    <v-chip small label>
                      属地{{ sGroup.sdCounts | fixTowNumber }}人
                    </v-chip>
                    <v-chip small label>
                      驻外{{ sGroup.zwCounts | fixTowNumber }}人
                    </v-chip>
                  </div>
                </v-col>

                <v-col cols="12" md="12" sm="5">
                  <v-banner elevation="2" class="text-subtitle-2">
                    <!-- <v-icon slot="icon" color="warning" size="25">
                    mdi-account
                  </v-icon> -->
                    <div class="MBCELLBOX">
                      <div class="MBCELLBOXGROUP" v-for="(mbs, z) in sgMemberGroup(sGroup)" :key="z">
                        <MBCELL v-for="(mb, k) in mbs" :mbobj="mb" :key="z * 1000 + k" />
                      </div>
                    </div>
                  </v-banner>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-simple-table dense fixed-header>
                <template v-slot:default>
                  <thead>
                    <tr class="text-left">
                      <th>序号</th>
                      <th>姓名</th>
                      <th>岗位</th>
                      <th>隶属</th>
                      <th>派驻开始时间</th>
                      <th>计划撤离时间</th>
                      <th>是否出差</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, k) in sGroup.members" :key="k" class="text-left">
                      <td>{{ k + 1 }}</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.grade }}</td>
                      <td>
                        {{ item.belong }}
                      </td>
                      <td>{{ item.cmddate | shorttime }}</td>
                      <td>{{ item.plandate | shorttime }}</td>
                      <td>{{ item.isbtrip == 1 ? '是' : '' }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

            </v-expansion-panel-content>
          </v-expansion-panel>


          <v-expansion-panel>
            <v-banner elevation="2" class="text-subtitle-2">
              <b>出差人员汇总</b>
              <table border="0" cellpadding="0" cellspacing="0" class="cctable">
                <tr v-for="(mb, z) in ccs" :key="z">
                  <td style="width: 20px;">
                    <span v-show="isOnlyCC(mb.station)">
                      {{ z + 1 }}.
                    </span>
                  </td>
                  <td>
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <div class="cellchip" v-bind="attrs" v-on="on">
                          <span :style="ccnamestationcss(mb.station)">{{ mb.oriName }}</span>
                        </div>
                      </template>
                      <template>
                        <table>
                          <tr>
                            姓名：{{ mb.oriName }}
                          </tr>
                          <tr>
                            站点：{{ mb.station }}
                          </tr>
                          <tr>
                            隶属：{{ mb.belong }}
                          </tr>
                          <tr>
                            岗位：{{ mb.grade }}
                          </tr>
                          <tr>
                            驻外开始时间：{{ mb.cmddate | shorttime }}
                          </tr>
                          <tr>
                            计划撤离时间：{{ mb.plandate | shorttime }}
                          </tr>
                        </table>
                      </template>
                    </v-tooltip>
                  </td>
                  <td :style="ccnamestationcss(mb.station)" style="width: 100px;text-align: center;">{{ mb.station }}
                  </td>
                </tr>
              </table>
            </v-banner>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-banner elevation="2" class="text-subtitle-2">
              <v-list v-if="lunhuangList.length > 0">
                <v-list-item>
                  <v-list-item-title> 近期人员调整： </v-list-item-title>
                </v-list-item>
                <v-list-item v-for="(val, index) in lunhuangList" :key="index">
                  <v-list-item-icon>
                    <v-icon color="indigo">
                      {{ val.icon }}
                    </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title class="text-button">
                      {{ val.text }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <span :class="val.textColor">
                        {{ val.mes }}
                        <small>
                          ({{ val.delt }})
                        </small>
                      </span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-banner>
          </v-expansion-panel>
        </v-expansion-panels>
        <SZX></SZX>
      </v-card-text>

      <!-- <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close_func"> 关闭</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions> -->
    </v-card>
  </div>
</template>
<script>
import Stanp from "@/js/stanp.js";
import { StanpAtget } from "@/js/axreqs.js";
import MBCELL from "./member_cell";
import SZX from "./szx.vue";
import { lhParse } from "@/js/dts.js";

export default {
  components: {
    MBCELL,
    SZX,
  },
  props: {},
  data() {
    return {
      panel: [],
      sts: [],
      ccs: [],
      lunhuangList: [],
    };
  },
  computed: {
    AllStationNames() {
      return [...new Set(this.sts.map((a) => a.station))];
    },
    CcStationNames() {
      return this.AllStationNames.filter((station) =>
        this.getBy("station", station).every(stanp => stanp.isbtrip == 1)
      );
    },
    StationNames() {
      return this.AllStationNames.filter((station) =>
        !this.getBy("station", station).every(stanp => stanp.isbtrip == 1)
      );
    },
    GroupByStation() {

      return this.StationNames.map((n) => {
        let members = this.getBy("station", n)
          .sort((a, b) => b.belong.localeCompare(a.belong))
          .sort((a, b) => a.grade.localeCompare(b.grade));
        let findedIndex = members.findIndex(p => p.grade.includes("负责") || p.grade.includes("管理"));
        if (findedIndex >= 0) {
          let tcharge = members[findedIndex];
          members.splice(findedIndex, 1);
          members.unshift(tcharge);
        }
        return {
          members,
          stationName: n,
          counts: members.length,
          fxCounts: members.filter((a) => a.grade.includes("放行")).length,
          jxyCounts: members.filter((a) => a.grade.includes("机械员")).length,
          totals: this.getTotals(members),
          sdCounts: members.reduce((t, c) => c.belong.includes(c.station) ? t + 1 : t, 0),
          zwCounts: members.reduce((t, c) => !c.belong.includes(c.station) && c.isbtrip == 0 ? t + 1 : t, 0),
          charger: findedIndex >= 0 ? members[0].name : "",
        };
      });
    },
    allStiaionCpts() {
      return this.getTotals(this.sts).value.map((a) =>
        String(a).padStart(2, "0")
      );
    },
    counts() {
      return this.sts.length;
    },
    sd_counts() {
      return this.allStiaionCpts[2];
    },
    zw_counts() {
      return this.counts - this.sd_counts - this.cc_counts;
    },
    cc_counts() {
      return this.ccs.length;
    },
    ismsfail() {
      return this.$store.state.main_service_fail;
    },
    serviceMsg() {
      if (this.ismsfail) {
        return `(主服务器连接失效，使用备用服务器)`;
      } else {
        return '';
      }
    },

  },
  methods: {
    isOnlyCC(station) {
      return this.CcStationNames.includes(station);
    },
    ccnamestationcss(station) {
      return this.isOnlyCC(station) ? "" : "color:gray"
    },
    sgMemberGroup(sGroup) {
      const howCountPerline = 10;
      const groupMembers = []
      // const members = sGroup.members.sort((a, b) => a.grade.localeCompare(b.grade))
      const members = sGroup.members
      for (let index = 0; index < members.length; index += howCountPerline) {
        groupMembers.push(members.slice(index, index + howCountPerline))
      }
      return groupMembers;
    },
    getBy(field, value, attrid = null) {
      return this.sts
        .filter((a) => a[field] == value)
        .map((b) => (attrid ? b[attrid] : b));
    },


    // constructor(name,station,grade,isLocal,szxTeam){
    getTotals(items) {
      let ots = Array(17).fill(0);
      for (let v of items) {
        if (v.belong.includes(v.station)) {
          ots[0] += v.grade.includes("放行");
          ots[1] += v.grade.includes("机械员");
        } else {
          if (v.grade.includes("放行")) {
            ots[14] += ["长期", "其他", "大修"].includes(v.belong);
            ots[6] += v.belong === "一中队";
            ots[8] += v.belong === "二中队";
            ots[10] += v.belong === "三中队";
            ots[12] += v.belong === "四中队";
          } else if (v.grade.includes("机械员")) {
            ots[15] += ["长期", "其他", "大修"].includes(v.belong);
            ots[7] += v.belong === "一中队";
            ots[9] += v.belong === "二中队";
            ots[11] += v.belong === "三中队";
            ots[13] += v.belong === "四中队";
          }
        }
      }
      ots[2] = ots[0] + ots[1]; //属地总人数
      ots[3] = ots[6] + ots[8] + ots[10] + ots[12]; //派遣放行
      ots[4] = ots[7] + ots[9] + ots[11] + ots[13]; //派遣机械员
      ots[5] = ots[3] + ots[4]; //派遣总人数
      ots[16] = ots[14] + ots[15]; //其他总人数

      // let sd_en0,sd_me1,sd_tl2,pq_en3,pq_me4,pq_tl5,
      //t1_en6, t1_me7,t2_en8,t2,me9,t3_en10,t3_me11,t4_en12,t4_me13,
      //cq_en14,cq_me15,cq_tl=16
      return {
        sdfx: ots[0],
        sdjxy: ots[1],
        sdzrs: ots[2],
        pqfx: ots[3],
        pqjxy: ots[4],
        pqzrs: ots[5],
        cqfx: ots[14],
        cqjxy: ots[15],
        cqzrs: ots[16],
        pq1fx: ots[6],
        pq1jxy: ots[7],
        pq2fx: ots[8],
        pq2jxy: ots[9],
        pq3fx: ots[10],
        pq3jxy: ots[11],
        pq4fx: ots[12],
        pq4jxy: ots[13],
        value: ots,
      };
    },
    init(stpes) {
      this.sts.push(
        ...stpes.filter(f => f.cmd.startsWith("on"))
          .map(a => new Stanp(a))
          .sort((a, b) => a.belong.localeCompare(b.belong))
          .sort((k, l) => k.station.localeCompare(l.station))

      );

      this.ccs.push(...stpes.filter(f =>
        f.cmd.startsWith("on") && f.iidinfo && f.isbtrip == 1)
        .sort((a, b) => a.station.localeCompare(b.station))
        .sort((a, b) =>
          this.StationNames.includes(a.station) - this.StationNames.includes(b.station)
        )
        .map(a => new Stanp(a)));
      this.lunhuangList = stpes
        .filter((f) => f.cmd.endsWith("ing"))
        .map((lh) => lhParse(new Stanp(lh)))
        .sort((a, b) => a.delt - b.delt);
    },
    setservicestauts(v) {
      this.$store.commit('mschange', v);
    }
  },

  filters: {
    fixTowNumber(num) {
      return String(num).padStart(2, "0");
    },
    shorttime(timestr) {
      let d = new Date(timestr);
      if (isNaN(d.getTime())) {
        return timestr;
      } else {
        return d.toLocaleDateString();
      }
    },
  },
  created() {
    StanpAtget(//node home 服务端正常
      (res) => {
        this.setservicestauts(false);
        this.init(res);
      },
      () => {//node home 服务端异常,使用备用服务器
        console.log("使用备用服务器")
        StanpAtget(res => {
          this.setservicestauts(true);
          this.init(res);
        })
      }
    );
  },
};
</script>
<style lang="less">
.warntext {
  color: rgb(177, 177, 19)
}

.imagetable {
  font-family: verdana, arial, sans-serif;
  font-size: 11px;
  color: #333333;
  border-width: 1px;
  border-color: #999999;
  border-collapse: collapse;

  th {
    border-width: 0px;
    padding: 8px;
    border-style: solid;
    border-color: #999999;
  }

  td {
    border-width: 0px;
    padding: 8px;
    border-style: solid;
    text-align: center;
    background: lightcyan;
  }

}


table.sd {
  .imagetable;

  th {
    background: #b5cfd2;
  }
}

table.pq {
  .imagetable;

  th {
    background: lightsteelblue;
  }
}

.totalshow {
  float: left;
}

.sepdived {
  border: 2px solid;
  float: left;
  height: 1px;
  color: white;
}

.MBCELLBOX {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.MBCELLBOXGROUP {
  display: flex;
  flex-wrap: wrap;

}

.cellchip {
  background-color: rgb(213, 231, 231);
}

.stationschips {
  display: flex;

  * {
    margin-right: 1px;
  }
}

.cctable {
  td {
    border-bottom: 1px dashed gray;
    height: 2em;
  }

}
</style>