<template>
  <v-row justify="center">
    <v-dialog v-model="isshow" persistent max-width="650px">
      <v-card>
        <v-card-title>
          <span class="headline">按人员姓名查询驻外明细</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="4">
                <v-combobox :items="personnames" label="输入/选择人员：" v-model="scname"
                  :rules="[(v) => !!v || '需要输入名字']"></v-combobox>
              </v-col>
              <v-col cols="8"> </v-col>
            </v-row>
            <v-row>
              <v-simple-table dense fixed-header height="300px">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th v-for="(name, i) in colnames" :key="i">{{ name }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(r, j) in resultes" :key="j">
                      <td>{{ j + 1 }}</td>
                      <td>
                        {{ r.name }}
                      </td>
                      <td>
                        {{ r.station }}
                      </td>
                      <td>
                        {{ r.grade }}
                      </td>
                      <td>
                        {{ r.belong }}
                      </td>
                      <td>
                        {{ r.cmddate | shortdate }}
                      </td>
                      <td>
                        {{ r.levdate | shortdate }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-row>

            <p>
              <span style="color: red"> </span>
            </p>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="isshow = false">
            关闭
          </v-btn>
          <v-btn color="blue darken-1" text @click="doquery"> 查询 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { tbGet, AllNamesGet } from "@/js/axreqs.js";

export default {
  props: {
    dialog: Boolean,
  },
  components: {},
  data() {
    return {
      scname: null,
      personnames: [],
      resultes: [],
      colnames: [
        "次数",
        "姓名",
        "站点",
        "岗位",
        "隶属",
        "驻外日期",
        "撤离日期",
      ],
    };
  },
  computed: {
    isshow: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("change", !!val);
      },
    },
    ismsfail() {
      return this.$store.state.main_service_fail;
    },
  },
  methods: {
    doquery() {
      tbGet({
        fields: "name,station,grade,belong,cmddate,levdate",
        item: "name",
        value: this.scname,
      }).then((rb) => (this.resultes = rb));
    },
  },
  filters: {
    shortdate(v) {
      let d = new Date(v);
      if (v && !isNaN(d.getTime())) {
        return d.toLocaleDateString();
      } else {
        return "";
      }
    },
  },
  created() {
    AllNamesGet((names) => (this.personnames = names));
  },
};
</script>