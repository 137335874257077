//   belong: "三中队"
// cmd: "on-cl-ing"
// cmddate: "2021-03-19T16:00:00.000Z"
// grade: "机械员"
// iid: 382
// iidinfo: "袁福童,机械员,三中队"
// levdate: null
// mem: ""
// name: "刘思佳"
// plandate: "2021-06-20T16:00:00.000Z"
// station: "南通"
// uid: 2
function shortDate(dstr){
    return dstr?new Date(dstr).toLocaleDateString():"";
}
class Stanp{
    constructor(serveStanp){
        Object.assign(this,serveStanp);
        this.cmddate=shortDate(this.cmddate);
        this.levdate=shortDate(this.levdate);
        this.plandate=shortDate(this.plandate);
        this.oriName=this.name;
        // this.name+=this.iidinfo&&this.iidinfo.includes("出差")?"(出差)":"";
    }
}
// constructor
//(name,station,grade,isLocal,szxTeam){


export default Stanp;