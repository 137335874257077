<template>
    <v-expansion-panels>
        <v-expansion-panel>
            <v-expansion-panel-header>
                <v-row no-gutters>
                    <v-col cols="12" md="12" sm="5">

                    </v-col>

                    <v-col cols="12" md="12" sm="5">
                        <v-banner elevation="2" class="text-subtitle-2">
                            <h3>查看深圳航线生产人员</h3>
                        </v-banner>
                    </v-col>
                </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <div class="maincontainer">
                    <div class="leftdiv">
                        <v-simple-table dense fixed-header>
                            <template v-slot:default>
                                <thead></thead>
                                <tbody>
                                    <tr>
                                        <th colspan="5" class="text-center teal" style="color: lightcyan;">
                                            管理人员
                                        </th>
                                    </tr>
                                    <tr class="text-left">
                                        <th>序号</th>
                                        <th> 航线一中队 </th>
                                        <th> 航线二中队 </th>
                                        <th> 航线三中队 </th>
                                        <th> 航线四中队 </th>
                                    </tr>
                                    <tr v-for="(m, i) in managers" :key="'m' + i">
                                        <th>{{ i + 1 }}</th>
                                        <td v-for="(n, j) in m" :key="'mm' + i * 100 + j">
                                            <CELL :suser="n"></CELL>
                                        </td>
                                    </tr>
                                    <tr class="text-left">
                                        <th colspan="5" class="text-center teal" style="color: lightcyan;">
                                            在深放行人员
                                        </th>
                                    </tr>
                                    <tr class="text-left">
                                        <th>序号</th>
                                        <th>一中队</th>
                                        <th>二中队</th>
                                        <th>三中队</th>
                                        <th>四中队</th>
                                    </tr>
                                    <tr v-for="(m, i) in dispatchers" :key="'d' + i">
                                        <th>{{ i + 1 }}</th>
                                        <td v-for="(n, j) in m" :key="'dd' + i * 100 + j">
                                            <CELL :suser="n"></CELL>
                                        </td>
                                    </tr>

                                    <tr class="text-left">
                                        <th colspan="5" class="text-center teal" style="color: lightcyan;">
                                            出差驻外和其他外出人员
                                        </th>
                                    </tr>
                                    <tr class="text-left">
                                        <th>序号</th>
                                        <th>一中队</th>
                                        <th>二中队</th>
                                        <th>三中队</th>
                                        <th>四中队</th>
                                    </tr>
                                    <tr v-for="(m, i) in outmembers" :key="'o' + i">
                                        <th>{{ i + 1 }}</th>
                                        <td v-for="(n, j) in m" :key="'oo' + i * 100 + j">
                                            <CELL :suser="n"></CELL>
                                        </td>
                                    </tr>

                                    <tr class="text-left">
                                        <th colspan="5" class="text-center teal" style="color: lightcyan;">
                                            在深机械员
                                        </th>
                                    </tr>
                                    <tr class="text-left">
                                        <th>序号</th>
                                        <th>一中队</th>
                                        <th>二中队</th>
                                        <th>三中队</th>
                                        <th>四中队</th>
                                    </tr>
                                    <tr v-for="(m, i) in macheisner" :key="'ma' + i">
                                        <th>{{ i + 1 }}</th>
                                        <td v-for="(n, j) in m" :key="'maa' + i * 100 + j">
                                            <CELL :suser="n"></CELL>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </div>
                    <hr/>
                    <v-divider></v-divider>
                    <hr/>
                    <div class="rightdiv">
                        <v-simple-table>
                            <tbody>
                                <tr>
                                    <th colspan="5" class="text-center blue">
                                        人员统计
                                    </th>
                                </tr>
                 
                                <tr class="text-left">
                                    <th></th>
                                    <th>一中队</th>
                                    <th>二中队</th>
                                    <th>三中队</th>
                                    <th>四中队</th>
                                </tr>
                                <tr style="font-weight: bolder;color: red;">
                                    <th>总人数</th>
                                    <td v-for="(d,i) in allmerbers" :key="i">
                                        {{ d }}
                                    </td>
                                </tr>
                                <tr>
                                    <th>管理人员</th>
                                    <td v-for="(d,i) in managersmembers" :key="i">
                                        {{ d }}
                                    </td>
                                </tr>
                                <tr>
                                    <th>放行人员</th>
                                    <td v-for="(d,i) in dispathchersmembers" :key="i">
                                        {{ d }}
                                    </td>
                                </tr>
                                <tr>
                                    <th>机械员</th>
                                    <td v-for="(d,i) in macheisnermembers" :key="i">
                                        {{ d }}
                                    </td>
                                </tr>
                                <tr>
                                    <th>见习机械员</th>
                                    <td v-for="(d,i) in praticalmacheisnermembers" :key="i">
                                        {{ d }}
                                    </td>
                                </tr>
                                <tr>
                                    <th colspan="5" class="text-center blue">
                                        外出统计
                                    </th>
                                </tr>
                                <tr class="text-left">
                                    <th></th>
                                    <th>一中队</th>
                                    <th>二中队</th>
                                    <th>三中队</th>
                                    <th>四中队</th>
                                </tr>
                                <tr>
                                    <th>出差/驻外放行人员</th>
                                    <td v-for="(d,i) in outmembersDispathch" :key="i">
                                        {{ d }}
                                    </td>
                                </tr>
                                <tr>
                                    <th>出差/驻外机械员</th>
                                    <td v-for="(d,i) in outmembersMacheisner" :key="i">
                                        {{ d }}
                                    </td>
                                </tr>
                                <tr>
                                    <th>其他原因减少人员</th>
                                    <td v-for="(d,i) in otherReasanOutMembers" :key="i">
                                        {{ d }}
                                    </td>
                                </tr>
                                <tr>
                                    <th>在深放行人员</th>
                                    <td v-for="(d,i) in staDispathcermembers" :key="i">
                                        {{ d }}
                                    </td>
                                </tr>
                                <tr>
                                    <th>在深授权机械员</th>
                                    <td v-for="(d,i) in staMacheisnermebers" :key="i">
                                        {{ d }}
                                    </td>
                                </tr>
                                <tr>
                                    <th>在深见习机械员</th>
                                    <td v-for="(d,i) in staPraticalMacheisnermebers" :key="i">
                                        {{ d }}
                                    </td>
                                </tr>
                                <tr style="font-weight: bolder;color: red;">
                                    <th>在深总人数</th>
                                    <td v-for="(d,i) in staTotalMembers" :key="i">
                                        {{ d }}
                                    </td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </div>
                </div>

            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import { tbGet } from '../js/axreqs';
import CELL from "./szxcell.vue"

const groups = ['一中队', '二中队', '三中队', '四中队'];
const groupSort = {
    '一中队': 0,
    '二中队': 1,
    '三中队': 2,
    '四中队': 3,
};
const sortbyBelong = (a, b) => groupSort[a.belong] - groupSort[b.belong];
// const sortbyState = (a, b)=>b.state.localeCompare(a.state);
function groupbyBelongAt(grade, szxusers, state = ["正常"]) {
    const result = [];
    const gbyb = groups.map(groupname =>
        szxusers.filter(n => n.belong === groupname
            && (!grade || n.grade.includes(grade))
            && state.includes(n.state)
        ))
    // .sort(sortbyState));
    const maxLength = Math.max(...gbyb.map(n => n.length));
    for (let index = 0; index < maxLength; index++) {
        let belongGroup = [];
        for (let belongIndex = 0; belongIndex < groups.length; belongIndex++) {
            belongGroup.push(gbyb[belongIndex].at(index))
        }
        result.push(belongGroup);
    }

    return result;
}

function transpose(matrix) {
    return matrix.length>0?matrix[0]?.map((_, colIndex) => matrix.map(row => row[colIndex])):matrix;
}

export default {
    props: {
    },
    components: {
        CELL,
    },
    data() {
        return {
            szxusers: [],
        };
    },
    created() {
        tbGet({}, 'szxuser').then(res => this.szxusers = res)
    },
    computed: {
        groupusers() {
            return groups.map(groupname => this.szxusers
                .filter(n => n.belong === groupname))
        },
        allmerbers(){
            return this.groupusers.map(n=>n.length)
        },
        managers() {
            return [
                this.szxusers.filter(s => s.grade === "队长").sort(sortbyBelong),
                this.szxusers.filter(s => s.grade === "副队长").sort(sortbyBelong),
            ]
        },
        managersmembers(){
            return transpose(this.managers).map(n=>n.filter(m=>!!m).length);
        },
        dispatchers() {
            return groupbyBelongAt("放行", this.szxusers)
        },
        dispathchersmembers(){
            return this.staDispathcermembers.map((n,i)=>n+this.outmembersDispathch[i])
        },
        macheisner() {
            return groupbyBelongAt("机械员", this.szxusers)
        },
        macheisnermembers(){
            return this.staMacheisnermebers.map((n,i)=>n+this.outmembersMacheisner[i])
        },
        praticalmacheisnermembers(){
            return transpose(this.macheisner).map(n=>n.filter(m=>m?.grade==='见习机械员').length)
        },
        outmembers() {
            return groupbyBelongAt(null, this.szxusers, ["出差", "驻外","休假","其他"])
        },
        _outmembersExcute(){
            return groupbyBelongAt(null, this.szxusers, ["出差", "驻外"]) 
        },
        outmembersDispathch(){
            return transpose(this._outmembersExcute).map(n=>n.filter(m=>m?.grade==='放行').length)
        },
        outmembersMacheisner(){
            return transpose(this._outmembersExcute).map(n=>n.filter(m=>m?.grade==='机械员').length)
        },
        outmemberspraticalMacheisner(){
            return transpose(this._outmembersExcute).map(n=>n.filter(m=>m?.grade==='见习机械员').length)
        },
        staDispathcermembers(){
            return transpose(this.dispatchers).map(n=>n.filter(m=>!!m).length)
        },
        staMacheisnermebers(){
            return transpose(this.macheisner).map(n=>n.filter(m=>m?.grade==='机械员').length)
        },
        staPraticalMacheisnermebers(){
            return this.praticalmacheisnermembers.map((n,i)=>n-this.outmemberspraticalMacheisner[i])
        },
        otherReasanOutMembers(){
            return this.allOutMembers.map((n,i)=>n-this.outmembersDispathch[i]-this.outmembersMacheisner[i]-this.outmemberspraticalMacheisner[i])
        },
        allOutMembers(){
            return transpose(this.outmembers).map(n=>n.filter(m=>!!m).length)
        },
        staTotalMembers(){
            return this.allmerbers.map((n,i)=>n-this.allOutMembers[i]);
        },
        
    },
    methods: {

    },
    filters: {
    },
};
</script>
<style lang="less">
    .maincontainer{
        display: flex;
        flex-direction: row;
    }
    .leftdiv{
        // color: rgb(250, 245, 245);
    }
    .rightdiv{

    }
</style>