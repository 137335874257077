<template>

    <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
            <div v-if="isAvali" class="normala" :class="classes" v-bind="attrs" v-on="on">
                <div>{{ user.name }}</div>
                <div class="sublabel">
                    {{ sublabel }}
                </div>
            </div>
        </template>
        <template>
            <table v-if="isAvali">
                <tr>
                    姓名：{{ user.name }} {{ user.sex?"":"(女)" }}
                </tr>
                <tr>
                    隶属：{{ user.belong }}
                </tr>
                <tr>
                    岗位：{{ user.grade }}
                </tr>
                <tr>
                    状态：{{ user.state }}
                </tr>
                <tr>
                    资质：{{ user | zhizi }}
                </tr>
                <tr>
                    备注：{{ user.mem }}
                </tr>
            </table>
        </template>
    </v-tooltip>

</template>

<script>



export default {
    props: {
        suser: Object
    },
    components: {
    },
    data() {
        return {
            user: this.suser
        };
    },
    created() {
    },
    computed: {
        isAvali() {
            return !!this.user
        },
        isPrecitiMeinical() {
            return this.user.grade === "见习机械员"
        },
        isOut() {
            return this.user.state !== "正常"
        },
        isDispatch() {
            return this.user.grade === '放行'
        },
        classes() {
            return {
                pretical: this.isPrecitiMeinical,
                outmerber: this.isOut,
                dispatcher: this.isDispatch && this.isOut,
                girl:!this.user.sex
            }
        },
        sublabel(){
            return `${this.user.ismax?"M":" "}${this.user.canfly?"✈":" "}`
        }
    },
    methods: {
    },
    filters: {
        zhizi(user){
            return `${user.canfly?"空勤证":""} ${user.ismax?"MAX":""}`
        }
    },
};
</script>
<style lang="less" scoped>
.normala {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.pretical {
    background-color: lightgoldenrodyellow;
    color: gray;
}
.sublabel{
    color: green;
    font-size: xx-small;
    vertical-align: super;
    -webkit-transform: scale(0.8);
}
.outmerber {
    // color: blue;
}
.girl{
    color: rgb(255, 154, 170);
}

.dispatcher {
    background-color: rgb(230, 216, 188);
}
</style>